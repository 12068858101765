import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Radar, PolarArea } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

const WebsiteInsightsForm = ({ data }) => {

  const { t } = useTranslation();
  const { language } = useI18next();

  const location = useLocation();
  const locationSearch = location.search ? queryString.parse(location.search) : null;

  // Submit
  const [form, setForm] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting }
  } = useForm();

  const [url, setUrl] = useState(locationSearch ? locationSearch.url : localStorage.getItem("url"));

  // Handle Change URL
  const handleChangeUrl = ({ target }) => {
    setUrl(target.value);
    setValue("url", target.value);
    localStorage.setItem("url", target.value);
  };

  const [result, setResult] = useState(null);
  const [average, setAverage] = useState(null);

  const [dataCircle, setDataCircle] = useState(null);
  const [dataBarChart, setDataBarChart] = useState(null);
  const [dataRadar, setDataRadar] = useState(null);
  const [dataPolarArea, setDataPolarArea] = useState(null);

  const optionsBarChart = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: 14
          }
        }
      },
    },
  };

  const optionsRadar = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        pointLabels: {
          font: {
            size: 14
          }
        }
      }
    }
  };

  const optionsPolarArea = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14
          }
        }
      },
    },
    scales: {
      r: {
        beginAtZero: true,
      }
    }
  };

  function getColorsBarChart(data) {
    var colorsBarChart = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i] > 90) {
        colorsBarChart.push('rgba(0,201,167)');
      } else if (data[i] > 50) {
        colorsBarChart.push('rgba(255,193,7)');
      } else {
        colorsBarChart.push('rgba(237,76,120)');
      }
    }
    return colorsBarChart;
  }

  function getColorsPolarArea(data) {
    var colorsPolarArea = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i] > 90) {
        colorsPolarArea.push('rgba(0,201,167, 0.7)');
      } else if (data[i] > 50) {
        colorsPolarArea.push('rgba(255,193,7, 0.7)');
      } else {
        colorsPolarArea.push('rgba(237,76,120, 0.7)');
      }
    }
    return colorsPolarArea;
  }

  function getColorCircle(data) {
    var colorCircle = null;
    if (data > 90) {
      colorCircle = 'rgba(0,201,167, 1)';
    } else if (data > 50) {
      colorCircle = 'rgba(255,193,7, 1)';
    } else {
      colorCircle = 'rgba(237,76,120, 1)';
    }
    return colorCircle;
  }

  const onSubmit = async data => {

    setForm(true);
    setSubmitting(true);
    setSubmitted(false);
    setSubmitError(false);

    // Url 
    var url = data.url;
    setUrl(data.url);

    await fetch(`https://pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed?url=${data.url}&category=PERFORMANCE&category=ACCESSIBILITY&category=BEST_PRACTICES&category=SEO&category=PWA&locale=fr&strategy=DESKTOP&key=${process.env.GATSBY_GOOGLE_API_KEY}`, {
      method: "GET",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(result => {
        if (result.id) {

          // Circle 
          setDataCircle({
            performance: {
              score: result.lighthouseResult.categories['performance']['score'] * 100,
              color: getColorCircle(result.lighthouseResult.categories['performance']['score'] * 100),
            },
            accessibility: {
              score: result.lighthouseResult.categories['accessibility']['score'] * 100,
              color: getColorCircle(result.lighthouseResult.categories['accessibility']['score'] * 100),
            },
            best_practices: {
              score: result.lighthouseResult.categories['best-practices']['score'] * 100,
              color: getColorCircle(result.lighthouseResult.categories['best-practices']['score'] * 100),
            },
            seo: {
              score: result.lighthouseResult.categories['seo']['score'] * 100,
              color: getColorCircle(result.lighthouseResult.categories['seo']['score'] * 100),
            },
            pwa: {
              score: result.lighthouseResult.categories['pwa']['score'] * 100,
              color: getColorCircle(result.lighthouseResult.categories['pwa']['score'] * 100),
            }
          });

          // Bar Chart
          var dataBarChart = [
            result.lighthouseResult.categories['performance']['score'] * 100,
            result.lighthouseResult.categories['accessibility']['score'] * 100,
            result.lighthouseResult.categories['best-practices']['score'] * 100,
            result.lighthouseResult.categories['seo']['score'] * 100,
            result.lighthouseResult.categories['pwa']['score'] * 100,
          ];
          var backgroundColorsBarChart = getColorsBarChart(dataBarChart);
          var borderColorsBarChart = getColorsBarChart(dataBarChart);
          setDataBarChart({
            labels: [t('results.scores.performance.title'), t('results.scores.accessibility.title'), t('results.scores.bestPractices.title'), t('results.scores.seo.title'), t('results.scores.pwa.title')],
            datasets: [
              {
                data: dataBarChart,
                backgroundColor: backgroundColorsBarChart,
                borderColor: borderColorsBarChart,
                borderWidth: 1,
              },
            ],
          });

          // Radar
          var dataRadar = [
            result.lighthouseResult.categories['performance']['score'] * 100,
            result.lighthouseResult.categories['accessibility']['score'] * 100,
            result.lighthouseResult.categories['best-practices']['score'] * 100,
            result.lighthouseResult.categories['seo']['score'] * 100,
            result.lighthouseResult.categories['pwa']['score'] * 100,
          ];
          setDataRadar({
            labels: [t('results.scores.performance.title'), t('results.scores.accessibility.title'), t('results.scores.bestPractices.title'), t('results.scores.seo.title'), t('results.scores.pwa.title')],
            datasets: [
              {
                data: dataRadar,
                backgroundColor: 'rgba(55,125,255, 0.2)',
                borderColor: 'rgb(55,125,255)',
                pointBackgroundColor: 'rgb(55,125,255)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(55,125,255)',
                borderWidth: 1,
              },
            ],
          });

          // Bar Chart
          var dataPolarArea = [
            result.lighthouseResult.categories['performance']['score'] * 100,
            result.lighthouseResult.categories['accessibility']['score'] * 100,
            result.lighthouseResult.categories['best-practices']['score'] * 100,
            result.lighthouseResult.categories['seo']['score'] * 100,
            result.lighthouseResult.categories['pwa']['score'] * 100,
          ];
          var backgroundColorsPolarArea = getColorsPolarArea(dataPolarArea);
          setDataPolarArea({
            labels: [t('results.scores.performance.title'), t('results.scores.accessibility.title'), t('results.scores.bestPractices.title'), t('results.scores.seo.title'), t('results.scores.pwa.title')],
            datasets: [
              {
                data: dataPolarArea,
                backgroundColor: backgroundColorsPolarArea,
                borderWidth: 1,
              },
            ],
          });

          // Result 
          var average = ((
            result.lighthouseResult.categories['performance']['score'] * 100
            + result.lighthouseResult.categories['accessibility']['score'] * 100
            + result.lighthouseResult.categories['best-practices']['score'] * 100
            + result.lighthouseResult.categories['seo']['score'] * 100
            + result.lighthouseResult.categories['pwa']['score'] * 100
          ) / 5);
          setAverage(average);

          if (average > 90) {
            setResult('good');
          } else if (average > 70) {
            setResult('needs_improvement');
          } else {
            setResult('poor');
          }

          // Message
          var message = t('contact.message.title', { url: url });
          message += '<br>';
          message += t('contact.message.average', { average: average });
          message += '<br>';
          message += t('contact.message.performance', { performance: result.lighthouseResult.categories['performance']['score'] * 100 });
          message += '<br>';
          message += t('contact.message.accessibility', { accessibility: result.lighthouseResult.categories['accessibility']['score'] * 100 });
          message += '<br>';
          message += t('contact.message.bestPractices', { bestPractices: result.lighthouseResult.categories['best-practices']['score'] * 100 });
          message += '<br>';
          message += t('contact.message.seo', { seo: result.lighthouseResult.categories['seo']['score'] * 100 });
          message += '<br>';
          message += t('contact.message.pwa', { pwa: result.lighthouseResult.categories['pwa']['score'] * 100 });

          var data = {
            'url': url,
            'message': message
          }

          fetch(process.env.GATSBY_API_URL + 'messages', {
            method: "POST",
            mode: 'cors',
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.GATSBY_API_KEY,
              "api-secret-key": process.env.GATSBY_API_SECRET_KEY,
              "form-uuid": process.env.GATSBY_FORM_WEBSITE_INSIGHTS_UUID,
              "language-code": language,
            },
            body: JSON.stringify(data),
          })
            .then(response => response.json())
            .then(result => {
              if (result.error === false) {
                setSubmitting(false);
                setSubmitted(true);
                setSubmitError(false);
                reset();
              } else if (result.error === true) {
                setForm(false);
                setSubmitting(false);
                setSubmitted(false);
                setSubmitError(true);
                setError(
                  "submit",
                  "submitError",
                );
              }
            })
            .catch(error => {
              setForm(false);
              setSubmitting(false);
              setSubmitted(false);
              setSubmitError(true);
              setError(
                "submit",
                "submitError",
                `${t('form.error')} ${error.message}`
              );
            });

        } else {
          setForm(false);
          setSubmitting(false);
          setSubmitted(false);
          setSubmitError(true);
          setError(
            "submit",
            "submitError",
          );
        }
      })
      .catch(error => {
        setForm(false);
        setSubmitting(false);
        setSubmitted(false);
        setSubmitError(true);
        setError(
          "submit",
          "submitError",
          `${t('form.error')} ${error.message}`
        );
      });
  };

  const showForm = (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} method="post">
        <div className="input-card input-card-sm">
          <div className="input-card-form">
            <Controller
              name="url"
              id="url"
              rules={{
                required: {
                  value: true,
                  message: t('form.url.required'),
                },
                pattern: {
                  value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                  message: t('form.url.valid'),
                },
              }}
              defaultValue={url}
              disabled={isSubmitting}
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  className="form-control form-control-lg"
                  placeholder={t('form.url.placeholder')}
                  maxLength="2000"
                  value={url}
                  onChange={handleChangeUrl}
                />
              )}
            />
            {errors.url && errors.url.type === "required" && <div className="text-danger">{t('form.url.required')}</div>}
            {errors.url && errors.url.type === "pattern" && <div className="text-danger">{t('form.url.valid')}</div>}
          </div>
          <button type="submit" className="btn btn-primary btn-lg" disabled={isSubmitting}><i className="bi bi-search me-1"></i> {t('form.analyse')}</button>
        </div>
      </form>
    </div>
  );

  const showSubmitting = (
    <div className="card card-lg">
      <div className="card-body">
        <div className="text-center">
          <h3>{t('loading.title', { url: url })}</h3>
          <p>{t('loading.subtitle', { url: url })}</p>
          <div className="d-flex justify-content-center align-items-center vh-50">
            <div className="spinner-border spinner-border-xl text-primary" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <span className="text-muted">{t('loading.poweredBy')}</span>
        <a href="https://pagespeed.web.dev/" target="_blank" rel="noopener noreferrer">
          <span className="text-muted ms-1">{t('loading.pagespeedInsights')}</span> <StaticImage className="img-fluid" quality="30" src="../../images/brands/google-pagespeed-insights-icon.svg" alt={t('results.pagespeedInsights')} width={20} height={20} />
        </a>
      </div>
    </div>
  );

  const showSubmitted = (
    <div className="card card-lg">
      <div className="card-header border-bottom border-2">
        <h3 className="card-header-title" dangerouslySetInnerHTML={{ __html: t('results.title', { url: url }) }}></h3>
        <small className="text-muted">{t('results.subtitle', { url: url })}</small>
      </div>
      <div className="card-body">
        <div className="w-lg-75 mx-lg-auto">
          {result === 'good' &&
            <div>
              <h3>{t('results.good.title', { average: average })}</h3>
              <p>{t('results.good.subtitle', { average: average })}</p>
              <StaticImage className="img-fluid" quality="30" src="../../images/illustrations/resources/website-insights/la-small-agency-website-insights-good.svg" alt="Image Description" />
            </div>
          }
          {result === 'needs_improvement' &&
            <div>
              <h3>{t('results.needsImprovement.title', { average: average })}</h3>
              <p>{t('results.needsImprovement.subtitle', { average: average })}</p>
              <StaticImage className="img-fluid" quality="30" src="../../images/illustrations/resources/website-insights/la-small-agency-website-insights-needs-improvement.svg" alt="Image Description" />
            </div>
          }
          {result === 'poor' &&
            <div>
              <h3>{t('results.poor.title', { average: average })}</h3>
              <p>{t('results.poor.subtitle', { average: average })}</p>
              <StaticImage className="img-fluid" quality="30" src="../../images/illustrations/resources/website-insights/la-small-agency-website-insights-poor.svg" alt="Image Description" />
            </div>
          }
        </div>
        <div className="border-top border-2 mt-4 mb-7"></div>
        <div className="text-center">
          <div>
            <ul className="nav nav-segment nav-pills mb-7" role="presentation">
              <li className="nav-item">
                <a className="nav-link active" id="nav-circles-tab" href="#nav-circles" data-bs-toggle="pill" data-bs-target="#nav-circles" role="tab" aria-controls="nav-circles" aria-selected="true">{t('results.type.circles')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="nav-bar-chart-tab" href="#nav-bar-chart" data-bs-toggle="pill" data-bs-target="#nav-bar-chart" role="tab" aria-controls="nav-bar-chart" aria-selected="false">{t('results.type.barChart')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="nav-radar-tab" href="#nav-radar" data-bs-toggle="pill" data-bs-target="#nav-radar" role="tab" aria-controls="nav-radar" aria-selected="false">{t('results.type.radar')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="nav-polar-area-tab" href="#nav-polar-area" data-bs-toggle="pill" data-bs-target="#nav-polar-area" role="tab" aria-controls="nav-polar-area" aria-selected="false">{t('results.type.polarArea')}</a>
              </li>
            </ul>
          </div>
          <div className="tab-content mb-3">
            <div className="tab-pane fade show active" id="nav-circles" role="tabpanel" aria-labelledby="nav-circles-tab">
              {dataCircle &&
                <div className="row mb-7">
                  <div className="col-sm-6 col-lg-4 offset-lg-2 mb-7 mb-lg-4">
                    <div className="circles-chart" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('results.explanations.performance.titleTooltip')}>
                      <CircularProgressbar
                        value={dataCircle.performance.score}
                        text={dataCircle.performance.score}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          textSize: '1.5rem',
                          pathTransitionDuration: 4,
                          pathColor: dataCircle.performance.color,
                          textColor: dataCircle.performance.color,
                        })}
                      />
                    </div>
                    <div className="h4 mt-3">{t('results.explanations.performance.title')}</div>
                  </div>
                  <div className="col-sm-6 col-lg-4 mb-7 mb-lg-4">
                    <div className="circles-chart">
                      <CircularProgressbar
                        value={dataCircle.accessibility.score}
                        text={dataCircle.accessibility.score}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          textSize: '1.5rem',
                          pathTransitionDuration: 4,
                          pathColor: dataCircle.accessibility.color,
                          textColor: dataCircle.accessibility.color,
                        })}
                      />
                    </div>
                    <div className="h4 mt-3">{t('results.explanations.accessibility.title')}</div>
                  </div>
                  <div className="col-sm-6 col-lg-4 mb-7 mb-lg-4">
                    <div className="circles-chart">
                      <CircularProgressbar
                        value={dataCircle.best_practices.score}
                        text={dataCircle.best_practices.score}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          textSize: '1.5rem',
                          pathTransitionDuration: 4,
                          pathColor: dataCircle.best_practices.color,
                          textColor: dataCircle.best_practices.color,
                        })}
                      />
                    </div>
                    <div className="h4 mt-3">{t('results.explanations.bestPractices.title')}</div>
                  </div>
                  <div className="col-sm-6 col-lg-4 mb-7 mb-lg-4">
                    <div className="circles-chart">
                      <CircularProgressbar
                        value={dataCircle.seo.score}
                        text={dataCircle.seo.score}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          textSize: '1.5rem',
                          pathTransitionDuration: 4,
                          pathColor: dataCircle.seo.color,
                          textColor: dataCircle.seo.color,
                        })}
                      />
                    </div>
                    <div className="h4 mt-3">{t('results.explanations.seo.title')}</div>
                  </div>
                  <div className="col-sm-6 col-lg-4 mb-7 mb-lg-4">
                    <div className="circles-chart">
                      <CircularProgressbar
                        value={dataCircle.pwa.score}
                        text={dataCircle.pwa.score}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          textSize: '1.5rem',
                          pathTransitionDuration: 4,
                          pathColor: dataCircle.pwa.color,
                          textColor: dataCircle.pwa.color,
                        })}
                      />
                    </div>
                    <div className="h4 mt-3">{t('results.explanations.pwa.title')}</div>
                  </div>
                </div>
              }
            </div>
            <div className="tab-pane fade" id="nav-bar-chart" role="tabpanel" aria-labelledby="nav-bar-chart-tab">
              <Bar data={dataBarChart} options={optionsBarChart} />
            </div>
            <div className="tab-pane fade" id="nav-radar" role="tabpanel" aria-labelledby="nav-radar-tab">
              <Radar data={dataRadar} options={optionsRadar} />
            </div>
            <div className="tab-pane fade" id="nav-polar-area" role="tabpanel" aria-labelledby="nav-polar-area-tab">
              <PolarArea data={dataPolarArea} options={optionsPolarArea} />
            </div>
          </div>
        </div>
        <div className="border-top border-2 my-7"></div>
        <div className="container">
          <div className="w-lg-100 mx-lg-auto text-start">
            <div className="row">
              <div className="mb-3 mb-sm-5">
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#035A4B"></path>
                        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z" fill="#035A4B"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="mb-0">{t('results.explanations.performance.title')}</h4>
                  </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('results.explanations.performance.description') }}></p>
              </div>
              <div className="mb-3 mb-sm-5">
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z" fill="#035A4B" />
                        <path opacity="0.3" d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z" fill="#035A4B" />
                        <path d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z" fill="#035A4B" />
                      </svg>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="mb-0">{t('results.explanations.accessibility.title')}</h4>
                  </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('results.explanations.accessibility.description') }}></p>
              </div>
              <div className="mb-3 mb-sm-5">
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M12 20.0217L8.47345 21.7285L6.86905 18.1542L3.07048 17.1949L4.13894 13.4256L1.84573 10.249L5.0871 8.04825L5.37225 4.14081L9.2699 4.5385L12 1.72852L14.7301 4.5385L18.6277 4.14081L18.9129 8.04825L22.1542 10.249L19.861 13.4256L20.9295 17.1949L17.1309 18.1542L15.5265 21.7285L12 20.0217Z" fill="#008060"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 15.006L10.0463 16.0332C9.81245 16.1561 9.5232 16.0662 9.40025 15.8324C9.3513 15.7393 9.33445 15.6326 9.3522 15.5289L9.72535 13.3533L8.1447 11.8126C7.9555 11.6282 7.95165 11.3253 8.13605 11.1361C8.2095 11.0608 8.3057 11.0118 8.4098 10.9967L10.5942 10.6792L11.5711 8.69985C11.6881 8.46295 11.9749 8.3657 12.2118 8.4826C12.3061 8.52915 12.3825 8.60555 12.4291 8.69985L13.4059 10.6792L15.5903 10.9967C15.8518 11.0346 16.0329 11.2774 15.9949 11.5388C15.9798 11.6429 15.9308 11.7392 15.8555 11.8126L14.2748 13.3533L14.6479 15.5289C14.6926 15.7893 14.5177 16.0366 14.2573 16.0812C14.1537 16.099 14.047 16.0821 13.9539 16.0332L12.0001 15.006Z" fill="#008060"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="mb-0">{t('results.explanations.bestPractices.title')}</h4>
                  </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('results.explanations.bestPractices.description') }}></p>
              </div>
              <div className="mb-3 mb-sm-5">
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                        <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="mb-0">{t('results.explanations.seo.title')}</h4>
                  </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('results.explanations.seo.description') }}></p>
              </div>
              <div>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4409 22C13.5455 22 14.4409 21.1046 14.4409 20C14.4409 18.8954 13.5455 18 12.4409 18C11.3364 18 10.4409 18.8954 10.4409 20C10.4409 21.1046 11.3364 22 12.4409 22Z" fill="#035A4B"></path>
                        <path opacity="0.3" d="M9.04095 14.8L9.94095 16.1C10.6409 15.6 11.5409 15.3 12.4409 15.3C13.3409 15.3 14.2409 15.6 14.9409 16.1L15.8409 14.8C16.1409 14.3 16.0409 13.6 15.4409 13.4C14.5409 13 13.5409 12.7 12.4409 12.7C11.3409 12.7 10.3409 12.9 9.44095 13.4C8.84095 13.6 8.74095 14.3 9.04095 14.8Z" fill="#035A4B"></path>
                        <path opacity="0.3" d="M3.14096 5.80005L4.04095 7.19995C6.44095 5.59995 9.34094 4.69995 12.4409 4.69995C15.5409 4.69995 18.4409 5.59995 20.8409 7.19995L21.7409 5.80005C22.0409 5.30005 21.8409 4.70002 21.3409 4.40002C18.7409 2.90002 15.6409 2 12.4409 2C9.24094 2 6.14095 2.90002 3.54095 4.40002C3.04095 4.70002 2.84096 5.30005 3.14096 5.80005Z" fill="#035A4B"></path>
                        <path opacity="0.3" d="M6.14097 10.3L7.04096 11.7C8.64096 10.7 10.441 10.1 12.541 10.1C14.641 10.1 16.441 10.7 18.041 11.7L18.941 10.3C19.241 9.80005 19.141 9.10002 18.541 8.90002C16.741 7.90002 14.741 7.40002 12.541 7.40002C10.341 7.40002 8.34096 7.90002 6.54096 8.90002C5.94096 9.10002 5.74097 9.80005 6.14097 10.3Z" fill="#035A4B"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="mb-0">{t('results.explanations.pwa.title')}</h4>
                  </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: t('results.explanations.pwa.description') }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer border-top border-2">
        <span className="text-muted">{t('results.poweredBy')}</span>
        <a href="https://pagespeed.web.dev/" target="_blank" rel="noopener noreferrer">
          <span className="text-muted ms-1">{t('results.pagespeedInsights')}</span> <StaticImage className="img-fluid" quality="30" src="../../images/brands/google-pagespeed-insights-icon.svg" alt={t('results.pagespeedInsights')} width={20} height={20} />
        </a>
      </div>
    </div>
  );

  const showSubmitError = (
    <div className="card card-lg">
      <div className="card-body">
        <div className="w-lg-75 mx-lg-auto">
          <h3>{t('error.title')}</h3>
          <p>{t('error.subtitle')}</p>
          <StaticImage className="img-fluid" quality="30" src="../../images/illustrations/resources/website-insights/la-small-agency-website-insights-poor.svg" alt="Image Description" />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {form &&
        <div>
          {showForm}
        </div>
      }
      {submitting &&
        <div className="mt-4">
          {showSubmitting}
        </div>
      }
      {submitted &&
        <div className="mt-4">
          {showSubmitted}
        </div>
      }
      {submitError &&
        <div>
          {showSubmitError}
        </div>
      }
    </div>
  );
};

export default WebsiteInsightsForm