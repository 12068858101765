import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import Layout from '../../components/layout/Layout';
import WebsiteInsightsForm from '../../components/form/WebsiteInsights';

// Images
import headerBackgroundImage from '../../images/svg/components/card-11.svg';

const WebsiteInsights = ({ data }) => {

  const { t } = useTranslation();

  return (
    <Layout
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description')}
      pageKeywords={t('meta.keywords')}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
      headerStyle="light"
    >
      <div className="position-relative bg-img-start" style={{ backgroundImage: 'url(' + headerBackgroundImage + ')' }}>
        <div className="container content-space-t-5 content-space-t-lg-5 content-space-b-4 position-relative zi-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h1>{t('title')}</h1>
            <p>{t('subtitle')}</p>
          </div>
          <div className="row">
            <div className="container">
              <div className="w-md-100 w-lg-75 text-center mx-md-auto">
                <WebsiteInsightsForm />
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-bottom zi-1">
          <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 500H3000V0L0 500Z" />
          </svg>
        </div>
      </div>
    </Layout>
  )
}

export default WebsiteInsights

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        title
        image {
          url
          secureUrl
          type
          width
          height
        }
        addressStreet
        addressPostalCode
        addressCity
        addressCountry
        addressFull
        addressGoogleDirection
        addressLatitude
        addressLongitude
        email
        telephone
        telephoneIndex
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "resources-website-insights", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

